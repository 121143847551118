const CommonRouter = {
    dashboard: '/dashboard',
    company: '/my-companies',
    import_data: '/import-data',
    gstr1_prepare: '/gstr1-prepare',
    gstr1_view_invoice: '/gstr1-view-invoice',
    gstr1_upload: '/gstr1-upload',
    gstr1_file: '/gstr1-file',
    profile: '/profile',
    setting: '/setting',
    company_setting: '/setting/company-settings',
    menu_company_setting: '/company-settings',
    user_activity: '/setting/user-activity',
    menu_user_activity: '/user-activity',
    setting_billing_plan: '/setting/billing-plans',
    import_gov_excel: '/import-gov-excel',
    munim_connector: '/import-munim-connector',
    munim_excel_template: '/munim-excel-template',
    copy_paste_template: '/copy-paste-template',
    import_json_file: '/import-json-file',
    import_file_log: '/setting/imported-file-log',
    menu_import_file_log: '/imported-file-log',
    gstr1_iff: '/gstr1-iff',
    gstr1_iff_view_invoice: '/gstr1-iff-view-invoice',
    gstr1_govt_summary: '/gstr1-govt-summary',
    gstr1_iff_govt_summary: '/gstr1-iff-govt-summary',
    gstr1_iff_import_data: '/gstr1-iff/import-data',
    gstr1_iff_import_data_copy_paste_template: '/import-data/copy-paste-template',
    gstr1_iff_import_gov_excel: '/gstr1-iff/import_data/import-gov-excel',
    gstr1_iff_import_json_file: '/gstr1-iff/import-data/import-json-file',
    gstr1_iff_import_munim_connector: '/gstr1-iff/import-data/munim-connector',
    gstr1_iff_munim_excel_template: '/gstr1-iff/import-data/munim-excel-template',
    gstr1_iff_upload: '/gstr1-iff-upload',
    gstr1_iff_file: '/gstr1-iff-file',
    notification: '/setting/user-notification',
    gst_nil_return: '/gst-nil-return',
    gst_return_history: '/gst-return-history',
    gstr1_edit_advaced_receipt: '/gstr1-view-invoice/edit-advanced-receipt',
    gstr1_edit_hsn_summary: '/gstr1-view-invoice/edit-hsn-summary',
    gstr1_nil_rate_supplies: '/gstr1-view-invoice/edit-nil-rate',
    gstr1_edit_invoice: '/gstr1-view-invoice/edit-invoice',
    gstr1_add_invoice: '/gstr1-view-invoice/add-invoice',
    gstr1_add_credit_debit_note: '/gstr1-view-invoice/add-credit-debit-note',
    gstr1_edit_credit_debit_note: '/gstr1-view-invoice/edit-credit-debit-note',
    gstr1_add_advance_receipt: '/gstr1-view-invoice/add-advance-receipt',
    gstr1_edit_advance_receipt: '/gstr1-view-invoice/edit-advance-receipt',
    gstr1_add_b2cs_invoice: '/gstr1-view-invoice/add-b2cs-invoice',
    gstr1_edit_b2cs_invoice: '/gstr1-view-invoice/edit-b2cs-invoice',
    iff_edit_invoice: '/gstr1-iff-view-invoice/edit-invoice',
    iff_add_invoice: '/gstr1-iff-view-invoice/add-invoice',
    iff_edit_credit_debit_note: '/gstr1-iff-view-invoice/edit-credit-debit-note',
    iff_add_credit_debit_note: '/gstr1-iff-view-invoice/add-credit-debit-note',
    gstr1_adjustment_advanced_amendment: '/gstr1-view-invoice/edit-adjustment-advanced',
    review_import_data: '/import-data/review',
    review_data: '/review_data',
    gstr1_edit_document_series: '/gstr1-view-invoice/edit-document-series',
    gstr1_iff_uploaded_error_data: '/gstr1-iff-uploaded-error-data',
    feature_request_setting: '/setting/feature-request',
    details_feature_request_setting: '/setting/feature-request/details',
    roadmap_feature_request_setting: '/setting/feature-request/roadmap',
    survey_feature_request_setting: '/setting/feature-request/survey',
    review_import_simple_sale_invoice_temp: '/import-data/simple-sale-invoice-template-review',
    review_import_credit_debit_temp: '/import-data/credit-debit-template-review',
    review_import_invoice_bill_supply_temp: '/import-data/invoice-bill-of-supply-template-review',
    cmp08: '/cmp-08',
    dummy_html_route: '/returns2/auth/gstr/ack',
    log_in: '/log-in',
    sign_up: '/sign-up',
    forgot_password: '/forgot-password',
    reset_password: '/reset-password',
    redirect_to_account: `${process.env.LOGIN_DOMAIN}/log-in?product=gst`
}

export default CommonRouter
