const commonRegex = {
    email: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
    invoice_no: new RegExp(/^[(1-9a-zA-Z)]/),
    invoice_date: new RegExp(/^(0?[1-9]|[12][0-9]|3[01])([-]|[/])(0?[1-9]|1[012])([-]|[/])\d{4}$/),
    gst_no: new RegExp(`([A-Z]){3}(A|B|C|F|G|H|L|J|P|T){1}([A-Z]){1}([0-9]){4}([0-9A-Z]){1}([1-9A-Z]){1}[Z|C]{1}([0-9A-Z]){1}`),
    tan_gst_no: new RegExp(`[D]{1}([0-9A-Z]){1}`),
    mobile_no: new RegExp(/^[6-9]\d{9}$/gi),
    pan_no: new RegExp(/^([A-Za-z]){3}(A|B|C|F|G|H|L|J|P|T){1}([A-Za-z]){1}([0-9]){4}([A-Za-z]){1}?$/),
    number_value: new RegExp(/^((\+|-)?(([0-9]*))(\.[0-9]+)?)$/g),
    number: new RegExp(/^[0-9]+$/),
    financial_year: new RegExp(/^\d{4}-\d{2}$/),
    portal_password: new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\@\!\#\%\^\$\&\`\*\-\_\+])(?=.*[a-zA-Z0-9\@\!\#\%\^\$\&\`\*\-\_\+]*$).{8,15}/)
}
export default commonRegex