const ValidationMessage = {
    is_require: 'Is required!',
    gst_valid: 'Please enter valid GSTIN!',
    gst_length: 'Gst number must contain 15 digit!',
    financial_year_prefix_valid: 'Financial year should not start with zero!',
    invoice_prefix_valid: 'Doc. No. should not start with zero!',
    mn_not_valid: 'Mobile number is not valid!',
    valid_email: 'Must be a valid email!',
    invoice_date_invalid: 'Invalid date',
    captcha_not_valid: 'Please enter a valid captcha!',
    financial_year_invalid: 'Financial year must be in YYYY-YY!',
    pan_valid: 'Please enter valid PAN no.!',
    new_confirm_pass: 'New password and confirm passwords should be match!',
    valid_password: 'Your password must be at least 6 characters. A recommended password is a combination of uppercase letters, lowercase letters, numbers, and symbols (!$@%).',
    otp_min_max: 'OTP is not valid!',
    otp_req: 'Please enter OTP!',
    mobile_no_valid: 'Mobile number is not valid!',
    future_date_not_allow: 'Future date is not allowed!',
    enter_valid_value: 'Please enter valid value',
    hsn_number: 'Must between 4 to 8 digits are required!',
    invalid_export_type: 'Type of Export is not valid',
    invalid_invoice_type: 'Invoice type is not valid',
    enter_valid_type: 'Please enter valid Type',
    hsn_number: 'Must between 4 to 8 digits are required!',
    token_length: 'Token must contain 10 digit!',
    invalid_length: 'Characters Length must be up to',
    invalid_year: 'Invalid Financial year',
    shipping_date: 'Shipping date can not be lower than invoice date',
    invalid_taxable_value: 'Taxable value should be greater than tax amounts',
    invalid_month: 'Original month is not valid',
    composition_date: 'Taxpayer type is Composition for the selected date!',
    invalid_place_supply: 'Place of supply is not valid',
    amount_not_zero: 'Amount should not be zero',
    sgst_cgst_eq_rate: 'SGST Rate and CGST Rate must be equal',
    sgst_cgst_eq_amt: 'SGST Amount and CGST Amount must be equal',
    negative_amount_not_allow: 'A negative value is not allowed for the amount.',
    cess_rate_not_valid: 'Cess Rate (%) never more than 100.',
    valid_portal_password: 'Password should be of 8 to 15 characters (where the minimum length is 8 characters and maximum length is 15 characters) which should comprise of at least one number, special character and letters( at least one upper and lower case)',
    invalid_unit_item: 'Item Unit (UoM) value is not valid for given hsn/sac code',
    invalid_quantity_item: 'Quantity is not valid for given hsn/sac code',
    rate_not_zero: 'Rate should not be zero',
    invalid_qty: 'Quantity is not valid',
    invalid_tex_item: 'Invalid tax applied on item',
    invalid_billing_gst: 'Billing GSTIN is not valid',
    signup_valid_password: 'Please follow password pattern!',
    confirm_pass: 'Confirm password must match with password!'
}
export default ValidationMessage
export const notifyMessage = {
    subscription_expire_0: 'Your current subscription has expired. Please renew to continue operation.',
    subscription_expire_1: 'Your current subscription has expired. Please contact your owner for renewal.',
    subscription_expire_2: 'Your current subscription has expired. Please contact your owner for renewal.',
    otp_error_message: 'You are not allowed to save changes, first get OTP and complete the verification process!',
    data_delete_success: 'Data has been deleted successfully.',
    pending_connection_request: 'Your connection request has not been approved yet. Try again after some time!',
    declined_connection_request: 'Your Munim connector request is declined!',
    disconnected_connection_request: 'Your Munim connection is disconnected!',
    disconnected_connection: 'Your Munim connection has been disconnected successfully.',
    remove_munim_connection: 'Your Munim connection has been removed successfully.',
    removed_connection: 'Your Munim connection request has been removed successfully.',
    data_not_found: 'Data not found!',
    data_update_success: 'Data has been updated successfully.',
    challan_download_success: 'Challan has been downloaded successfully.',
    support_token_copied: 'Support token is copied to clipboard.',
    no_que_found: 'No question found',
    select_bank: 'Please select bank',
    failed_challan_payment: 'Opps! Payment was not processed.',
    successful_challan_payment: 'Congratulations! Your payment completed successfully. You can click on check status option and do further process.',
    reg_type_updated: 'Registration Updated successfully.',
    temporarily_disconnect: "Your munim connection is temporarily removed by the Owner, Please contact and ask them to enable your connection.",
    challan_created_success: 'Challan has been created successfully.',
    pass_change_success: 'Password has been changed successfully.',
    summary_generated_success: 'Summary Generated Successfully',
    reset_gstn_data_success: 'GSTN Data Reset Successfully'
}
export const popUpMessage = {
    discard_title: 'Unsaved changes!',
    discard_content: 'If you discard changes, you’ll delete any edits you made since you last saved.',
    cancel_content: 'Leaving this page will delete all unsaved changes.'
}
