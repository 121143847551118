const CommonApiEndPoint = {
    reset_password: '/reset-password',
    forgot_password: '/forgot-password',
    log_in: '/log-in',
    sign_up: '/sign-up',
    change_password: '/change-password',
    update_profile: '/update-profile',
    verify_user_otp: '/verify-user-otp',
    verify_password: '/verify-password',
    create_filter: '/create-filter',
    update_filter: '/update-filter',
    create_company: '/create-company',
    update_company: '/update-company',
    update_company_status: '/update-company-status',
    activity_log_list: '/activity-log-list',
    get_user_activity: '/get-user-activity',
    create_activity_log: '/create-activity-log',
    change_email_mobile: '/change-email-mobile',
    set_invoice_status: '/set-invoice-status',
    next_year_stock_transfer: '/next-year-stock-transfer',
    cancel_einvoice: '/canceled-einvoice',
    update_email_preference: '/update-email-preference',
    create_email_preference: '/create-email-preference',
    cancel_eway_bill: '/canceled-eway-bill',
    import_gstr1: '/import-Gstr1',
    get_import_progress_status: '/get-import-progress-status',
    get_all_notification_list: '/get-all-notification-list',
    get_notification_list: '/get-notification-list',
    remove_notification: '/remove-notification',
    get_return_period_list: '/get-return-period-list',
    get_company_user: '/get-company-all-user',
    get_all_company_list: '/get-all-company-list',
    get_filter_detail: '/get-filter-details',
    next_year_transfer: '/next-year-transfer',
    get_opening_data: '/get-opening-data',
    get_bank_list: '/get-bank-list',
    get_customer_list: '/get-customer-list',
    get_states: '/get-states',
    get_cities: '/get-cities',
    get_gst_info: '/get-gst-info',
    get_credit_debit_note_data: '/get-credit-debit-note-data',
    get_credit_debit_note_data_iff: '/get-credit-debit-note-data-iff',
    get_invoice_data: '/get-invoice-data',
    get_invoice_data_iff: '/get-invoice-data-iff',
    get_gstr1_b2cs_data: '/get-gstr1-b2cs-data',
    get_invoice_summary: '/get-invoice-summary',
    get_gst_portal_data: '/get-gst-portal-data',
    upload_gstr1: '/upload-gstr1',
    get_invoice_summary_iff: '/get-invoice-summary-iff',
    get_advance_receipt_data: '/get-advance-receipt-data',
    get_transporter_list: '/get-transporter-list',
    company_list: '/company-list',
    company_details: '/company-details',
    gstr1_filing_status: '/gstr1-filing-status',
    get_gstr1_summary: '/get-gstr1-summary',
    set_gstr1_filing_data: '/set-gstr1-filing-data',
    get_uploaded_gst_data: '/get-uploaded-gst-data',
    get_uploaded_history: '/get-uploaded-history',
    get_import_log_list: '/get-import-log-list',
    get_return_history: '/get-return-history',
    delete_gst_data: '/delete-gst-data',
    get_all_customer_list: '/get-all-customer-list',
    get_all_gst_list: '/get-all-gst-list',
    gstr1_filling_status: '/get-gstr1-filing-status',
    get_dashboard_analitics_data: '/get-dash-analytic-data',
    get_profile: '/get-gst-utility-user-details',
    import_bulk_company: '/import-bulk-company',
    get_upload_via_otp_req: '/upload-via-otp-req',
    get_upload_via_otp_auth: '/upload-via-otp-auth',
    add_user_rating: '/add-user-rating',
    get_latest_news: '/get-latest-news',
    edit_invoice_data: '/edit-invoice-data',
    get_gstr1_sheet_data: '/get-gstr1-sheet-data',
    renew_token: '/renew-token',
    return_evc_file: '/return-evc-file',
    update_import_progress: '/update-import-progress',
    get_authorised_pan: '/get-authorised_pan',
    set_authorised_pan: '/set-authorised_pan',
    generate_otp: '/generate-otp',
    verify_user_otp: '/verify-user-otp',
    get_import_summary: '/get-import-summary',
    get_success_sheet_data: '/get-success-sheet-data',
    get_error_json: '/get-error-json',
    edit_error_json: '/edit-error-json',
    verify_user_request: '/verify-user-request',
    munim_connection_req: '/munim-connection-req',
    check_connection: '/check-connection',
    update_connection_status: '/update-connection-status',
    update_request_status: '/update-request-status',
    get_gstr1: '/get-gstr1',
    get_gstr_iff: '/get-gstr-iff',
    save_template_data: '/save-template-data',
    get_govt_notification_data: '/get-govt-notification-data',
    feature_request_comment: '/feature-request-comment',
    save_feature_request_upvote: '/save-feature-request-upvote',
    get_comment_list: '/get-comment-list',
    create_feature_req: '/create-feature-request',
    get_feature_request: '/get-feature-request',
    get_feature_request_details: '/get-feature-request-details',
    get_feature_request_pipeline: '/get-feature-request-pipeline',
    get_feature_request_in_development: '/get-feature-request-in-developement',
    get_feature_request_done: '/get-feature-request-done',
    get_not_uploaded_error_data: '/get-not-uploaded-error-data',
    incomplete_user_profile_status: '/incomplete-user-profile-status',
    update_company_frequency: '/update-company-frequency',
    edit_cmp_data: '/edit-cmp-data',
    get_cmp_data: '/get-cmp-data',
    delete_cmp_data: '/delete-cmp-data',
    check_company_user: '/check-company-exist',
    get_reg_type_history: '/get-reg-type-history',
    get_transaction_id: '/get-transaction-id',
    generate_login_otp: '/generate-login-otp',
    get_to_be_upload_data: '/get-to-be-upload-data',
    get_section_summary: '/get-section-summary'
}
export default CommonApiEndPoint
