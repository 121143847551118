import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    datePeriod: { value: '', label: '', en_id: '', success_filing_id: '', isDataAvailable: false, filing_type: '' },
    periodDataList: [],
    cmpHistoryList: [],
    yearPeriod:''
}

export const gstr1DataSlice = createSlice({
    name: 'headerAction',
    initialState,
    reducers: {
        setDatePeriod: (state, action) => {
            state.datePeriod = action.payload
        },
        setPeriodDataList: (state, action) => {
            state.periodDataList = action.payload
        },
        setCmpHistoryList: (state, action) => {
            state.cmpHistoryList = action.payload
        },
        setYearPeriod: (state, action) => {
            state.yearPeriod = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setDatePeriod, setPeriodDataList, setCmpHistoryList, setYearPeriod } = gstr1DataSlice.actions

export default gstr1DataSlice.reducer