// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import commonReducer from './commonSlice'
import listViewReducer from './listViewSlice'
import headerActionReducer from './headerActionSlice'
import windowResizeReducer from './windowResizeSlice'
import gstr1DataReducer from './gstr1DataSlice'
import supportChatReducer from './supportChatSlice'

const rootReducer = {
  auth,
  layout,
  navbar,
  commonReducer,
  listViewReducer,
  windowResizeReducer,
  headerActionReducer,
  gstr1DataReducer,
  supportChatReducer
}

export default rootReducer
